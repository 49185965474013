/* This example requires Tailwind CSS v2.0+ */
import React from "react";
import { PageProps } from "gatsby";

import SEO from "../components/seo";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import MyLink from "../components/MyLink";

// @ts-ignore
import erin from "../images/erin.jpeg";

export default function About(props: PageProps) {
  return (
    <div className="bg-white">
      <SEO
        title="About"
        description="Erin Kelly @erinkellyart, is a yoga and movement educator who’s currently traveling the United States leading in-person events. In addition to her playful in person workshops, she has a variety of online continued education courses available with her school Authentic Movements."
        image={erin}
      />
      <NavBar {...props} />
      <main>
        <div className="bg-white overflow-hidden">
          <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
            <div className="hidden lg:block bg-my-tan absolute top-0 bottom-0 left-3/4 w-screen" />
            <div className="mx-auto text-base z-10 max-w-prose lg:grid lg:grid-cols-1 lg:gap-8 lg:max-w-none">
              <h3 className="mt-2 text-3xl leading-8 contents lg:hidden font-extrabold tracking-tight text-my-orange sm:text-4xl">
                About Erin
              </h3>
            </div>
            <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
              <div className="relative lg:row-start-1 lg:col-start-2">
                <div className="relative text-base mx-auto max-w-prose lg:max-w-none">
                  <figure>
                    <div className="aspect-w-12 aspect-h-12 lg:aspect-none">
                      <img
                        className="rounded-lg shadow-lg object-cover object-top"
                        src={erin}
                        alt="Picture of Erin"
                      />
                    </div>
                  </figure>
                </div>
              </div>
              <div className="mt-8 lg:mt-0">
                <h3 className="mt-8 text-3xl hidden lg:contents font-extrabold tracking-tight text-my-orange sm:text-4xl">
                  About Erin
                </h3>
                <div className="mt-5 prose text-md lg:text-lg prose-indigo text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
                  <p>
                    Erin Kelly{" "}
                    <MyLink
                      eventName="about_instagram_clicked"
                      href="https://www.instagram.com/erinkellyart/"
                      linkType="outbound_link"
                    >
                      @erinkellyart
                    </MyLink>
                    {", "}
                    is a yoga and movement educator who’s currently traveling
                    the United States leading in-person events. In addition to
                    her playful in person workshops, she has a variety of online
                    continued education courses available with her school{" "}
                    <MyLink
                      eventName="about_am_clicked"
                      href="https://authenticmovements.com/"
                      linkType="outbound_link"
                    >
                      Authentic Movements
                    </MyLink>
                    . She has been practicing, studying, and teaching yoga since
                    2001 and grew up playing soccer, running track, and
                    competing as a swimmer. During her 2000+ hours of education,
                    Erin has studied with Ana Forrest, Noah Maze, Christina
                    Sell, Megan Currie, Yancy Swatch (Dharma & Rocket), and
                    Patrick Beach.
                  </p>
                  <p>
                    She is known for her energy and fluidity of movement in both
                    practice and teaching, and is often found upside down or
                    cultivating creativity through uncommon transitions. Her
                    teaching style is refreshing and playful, while still
                    incorporating precise cues and pose breakdowns to help
                    students understand their bodies and the path to personal
                    growth.
                  </p>
                  <p>
                    In 2016, Erin and Stevi Gongora started{" "}
                    <MyLink
                      eventName="about_am_clicked"
                      href="https://authenticmovements.com/"
                      linkType="outbound_link"
                    >
                      Authentic Movements
                    </MyLink>
                    , a movement science and yoga school that currently hosts a
                    wide variety of continued education courses, as well as a
                    module - hybrid 300 hr (500 track) yoga teacher training.
                  </p>
                  <p>
                    Presently, Erin is devoting her time traveling and teaching
                    in different cities to experience and share the practice in
                    person and in real time once again.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}
